import React from 'react';

import { useClassnames } from '@lumapps/classnames';
import { StepProps } from '@lumapps/lumx-layouts/types';
import { mdiEmailOutline } from '@lumapps/lumx/icons';
import { Icon, Size, Link, Text } from '@lumapps/lumx/react';
import { useTranslate } from '@lumapps/translations';

import { RESET_PASSWORD } from '../../../keys';

import './index.scss';

const CLASSNAME = 'check-emails-page';
export const CheckEmailsPage: React.FC<StepProps> = ({ goToPreviousStep, state }) => {
    const { translateKey } = useTranslate();
    const { element, block } = useClassnames(CLASSNAME);

    return (
        <div className={block()}>
            <div className={element('icon-wrapper')}>
                <Icon className={element('icon')} icon={mdiEmailOutline} size={Size.xl} hasShape />
            </div>
            {state !== undefined && (
                <div className={element('email-block')}>
                    <Text as="p">{translateKey(RESET_PASSWORD.LINK_HAS_BEEN_SENT_TO)}</Text>
                    <Text as="p" typography="subtitle1">
                        {state.email}
                    </Text>
                </div>
            )}

            <div className={element('reset-password-link')}>
                <Text as="p" typography="body2">
                    {translateKey(RESET_PASSWORD.EMAIL_NOT_RECEIVED)}
                    {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                    <Link className={element('link-text')} onClick={goToPreviousStep}>
                        {translateKey(RESET_PASSWORD.TRY_ANOTHER_ADDRESS)}
                    </Link>
                </Text>
            </div>
        </div>
    );
};
