import React from 'react';

import { Wizard } from '@lumapps/lumx-layouts/components/Wizard';
import { CheckEmailsStep } from '@lumapps/reset-password/components/CheckEmails/CheckEmailsStep/CheckEmailsStep';
import { RenewPasswordStep } from '@lumapps/reset-password/components/RenewPasswordStep/RenewPasswordStep';
import { ResetPasswordStep } from '@lumapps/reset-password/components/ResetPasswordStep/ResetPasswordStep';
import { useLocation, useParams } from '@lumapps/router';

import { signIn, STEPS, SignInRouteParams } from '../../routes';
import { ProvidersStep } from '../ProvidersStep';
import { SignInStep } from '../SignInStep';

export const SignInWizard: React.FC = () => {
    const params = useParams<SignInRouteParams>();
    const { search } = useLocation();
    return (
        <Wizard
            steps={[
                {
                    id: STEPS.PROVIDERS,
                    Component: ProvidersStep,
                    route: () => signIn({}),
                },
                {
                    id: STEPS.SIGN_IN,
                    Component: SignInStep,
                    route: () => signIn({ step: STEPS.SIGN_IN }, search),
                },
                {
                    id: STEPS.RENEW_PASSWORD,
                    Component: RenewPasswordStep,
                    route: () => signIn({ step: STEPS.RENEW_PASSWORD }),
                },
                {
                    id: STEPS.CHECK_EMAILS,
                    Component: CheckEmailsStep,
                    route: () => signIn({ step: STEPS.CHECK_EMAILS }),
                },
                {
                    id: STEPS.RESET_PASSWORD,
                    Component: ResetPasswordStep,
                    route: () => signIn({ step: STEPS.RESET_PASSWORD }),
                },
            ]}
            startingStepId={params.step}
        />
    );
};
