import React, { useEffect, useState } from 'react';

import uniqueId from 'lodash/uniqueId';

import { LoginProviderSyn } from '@lumapps/constants/types';
import { LoginPage } from '@lumapps/login/components/LoginPage';
import { LoginProviderButton } from '@lumapps/login/components/LoginProviderButton';
import { LOGIN_METHODS, LOGIN_PROVIDER_CONFIGURATION } from '@lumapps/login/constants';
import { LoginProviderButtonProps } from '@lumapps/login/types';
import { isEmbedded } from '@lumapps/login/utils/isEmbedded';
import { StepProps } from '@lumapps/lumx-layouts/types';
import { Link } from '@lumapps/lumx/react';
import { GLOBAL, useTranslate } from '@lumapps/translations';

import { COMPACT_LOGIN_LIST_LENGTH } from '../../constants';
import { SIGN_IN } from '../../keys';
import { LoginMessage } from '../LoginMessage';

export interface ProvidersStepProps extends StepProps {
    loginProviders: LoginProviderSyn[];
}

export const ProvidersStep: React.FC<ProvidersStepProps> = ({ goToNextStep, loginProviders }) => {
    const { translateKey } = useTranslate();
    const [isListReduced, setIsListReduced] = useState<boolean>(true);
    const [providerToDisplay, setProviderToDisplay] = useState<LoginProviderSyn[]>([]);
    const [focusButtonIndex, setFocusButtonIndex] = useState<number>(0);
    const [isLoading, setIsLoading] = useState<boolean>(false);

    useEffect(() => {
        if (isListReduced && loginProviders.length > COMPACT_LOGIN_LIST_LENGTH) {
            // reset the focus on the first item of the provider list
            setFocusButtonIndex(0);
            setProviderToDisplay(loginProviders.slice(0, COMPACT_LOGIN_LIST_LENGTH));
        } else {
            // set the focus on the first item of the hidden part of the provider list
            setFocusButtonIndex(COMPACT_LOGIN_LIST_LENGTH);
            setProviderToDisplay(loginProviders);
        }
    }, [isListReduced, loginProviders]);

    const onProviderButtonClick = (provider: LoginProviderSyn) => {
        setIsLoading(true);
        if (provider.type === LOGIN_METHODS.EMAIL) {
            goToNextStep();
        }
    };
    /**
     * in case of embedded login, this function return a new filtered array without email provider type
     */
    const providerListToDisplay = () => {
        return isEmbedded()
            ? providerToDisplay.filter((provider: LoginProviderSyn) => provider.type !== LOGIN_METHODS.EMAIL)
            : providerToDisplay;
    };

    return (
        <LoginPage
            title={translateKey(GLOBAL.LOG_IN)}
            hideTitle
            subtitle={translateKey(SIGN_IN.WELCOME_LOGIN_OPTIONS_SUBTITLE)}
            displayPoweredBy
        >
            <LoginMessage />
            {providerListToDisplay().map((provider: LoginProviderSyn, index: number) => {
                // retrieve the specific customization for each type of provider
                const providerConfiguration = LOGIN_PROVIDER_CONFIGURATION[
                    provider.type.toLowerCase()
                ] as LoginProviderButtonProps;
                return (
                    <LoginProviderButton
                        autoFocusButton={index === focusButtonIndex}
                        key={uniqueId('providerButton-')}
                        url={provider.url}
                        name={provider.name}
                        onClick={() => onProviderButtonClick(provider)}
                        leftImage={provider.logoUrl || providerConfiguration.leftImage}
                        loginMethod={providerConfiguration.loginMethod}
                        color={providerConfiguration.color}
                        backgroundColor={provider.buttonColor}
                        border={providerConfiguration.border}
                        isDisabled={isLoading}
                    />
                );
            })}

            {loginProviders.length > COMPACT_LOGIN_LIST_LENGTH && (
                /*  eslint-disable-next-line jsx-a11y/anchor-is-valid */
                <Link
                    onClick={() => {
                        setIsListReduced(!isListReduced);
                    }}
                >
                    {isListReduced
                        ? translateKey(SIGN_IN.SHOW_MORE_LOGIN_OPTIONS)
                        : translateKey(SIGN_IN.SHOW_LESS_LOGIN_OPTIONS)}
                </Link>
            )}
        </LoginPage>
    );
};
