import React, { useReducer, useRef } from 'react';

import { useClassnames } from '@lumapps/classnames';
import { errorMessage } from '@lumapps/login/utils/displayErrorMessage';
import { mdiEyeOutline } from '@lumapps/lumx/icons';
import { Button, Icon, Kind, Message, Size, TextField, Text } from '@lumapps/lumx/react';
import { useQueryParams } from '@lumapps/router';
import { SIGN_IN } from '@lumapps/sign-in/keys';
import { useTranslate } from '@lumapps/translations';

import { PASSWORD_MAX_LENGTH } from '../../constants';
import { actions, reducer, initialState } from '../../ducks/slice';
import './index.scss';

const CLASSNAME = 'reset-password-form';

export const ResetPasswordPage: React.FC = () => {
    const { token, error } = useQueryParams();
    const [state, dispatch] = useReducer(reducer, initialState);
    const { block, element } = useClassnames(CLASSNAME);
    const tokenValue = useRef<any | null>(token);
    const { translateKey } = useTranslate();

    /**  function when onchange confirm password: if new password and confirm password values are matching or not */
    const confirmPasswordChange = (value: string) => {
        dispatch(actions.onChangeConfirmPassword(value));
    };
    /**  function when onchange new password: check new password length value & matching new password and confirm password */
    const newPasswordChange = (value: string) => {
        dispatch(actions.onChangeNewPassword(value));
    };
    /**
     * Function that change the password type from password to text when clicking on the eye-outline icon
     */
    const togglePassword = () => {
        dispatch(actions.changePasswordType());
    };

    const isButtonDisabled =
        state.newPassword.length >= PASSWORD_MAX_LENGTH && state.confirmPassword === state.newPassword;
    return (
        <form className={block()} method="POST" action={window.location.pathname}>
            <TextField
                data-testid="new-password-input"
                name="password"
                className={element('input')}
                label={translateKey(SIGN_IN.NEW_PASSWORD)}
                onChange={newPasswordChange}
                value={state.newPassword}
                isValid={state.isValidNewPassword}
                hasError={state.isNewPasswordError}
                error={translateKey(state.newPasswordErrorMessage)}
                type={state.passwordType}
                afterElement={
                    <Icon
                        className={element('eye-password-icon')}
                        icon={mdiEyeOutline}
                        size={Size.xxs}
                        onClick={togglePassword}
                    />
                }
            />
            <Text
                as="p"
                typography="caption"
                color="dark"
                colorVariant="L2"
                className={element('password_requirement')}
            >
                {translateKey(SIGN_IN.PASSWORD_NOT_ENOUGH_CHARACTERS)}
            </Text>

            <TextField
                name="rePassword"
                className={element('input')}
                label={translateKey(SIGN_IN.CONFIRM_PASSWORD)}
                onChange={confirmPasswordChange}
                value={state.confirmPassword}
                isValid={state.isValidConfirmPassword}
                hasError={state.isConfirmPasswordError}
                error={translateKey(state.confirmPasswordErrorMessage)}
                type={state.passwordType}
                afterElement={
                    <Icon
                        className={element('eye-password-icon')}
                        icon={mdiEyeOutline}
                        size={Size.xxs}
                        onClick={togglePassword}
                    />
                }
            />
            <input id="token" type="hidden" name="token" value={tokenValue.current} />

            <Button isDisabled={!isButtonDisabled} className={element('button')} type="submit" fullWidth>
                {translateKey(SIGN_IN.RESET_YOUR_PASSWORD)}
            </Button>
            {errorMessage(error) && (
                <Message className={element('error-reset')} kind={Kind.error} hasBackground>
                    <Text as="p"> {translateKey(errorMessage(error))} </Text>
                </Message>
            )}
        </form>
    );
};
