import { Query, Route } from '@lumapps/router';
import { AppId } from '@lumapps/router/constants';

export enum STEPS {
    PROVIDERS = 'providers',
    SIGN_IN = 'sign-in',
    RENEW_PASSWORD = 'renew-password',
    CHECK_EMAILS = 'check-emails',
    RESET_PASSWORD = 'reset-password',
}

export interface SignInRouteParams {
    step?: STEPS;
}

const signInRoute: Route = {
    path: '/:step*',
    appId: AppId.login,
};

const signIn = ({ step }: SignInRouteParams, query?: Query): Route => {
    return {
        ...signInRoute,
        params: {
            step,
        },
        query,
    };
};

export { signIn, signInRoute };
