import React, { ElementType } from 'react';

import debounce from 'lodash/debounce';

import { useClassnames } from '@lumapps/classnames';
import { DEBOUNCE_DELAY } from '@lumapps/constants';
import { mdiMagnify } from '@lumapps/lumx/icons';
import {
    TextField,
    IconButton,
    FlexBox,
    FlexBoxProps,
    TextFieldProps,
    IconButtonProps,
    Orientation,
    Emphasis,
} from '@lumapps/lumx/react';
import { useTranslate, GLOBAL } from '@lumapps/translations';

import './index.scss';

export interface SearchFieldProps {
    /** search field icon label */
    label: string;
    /** callback to be executed once the user searches */
    onSearch: (text: string) => void;
    /** text field props */
    textFieldProps?: Omit<TextFieldProps, 'onChange'>;
    /** search icon button props */
    iconButtonProps?: Omit<IconButtonProps, 'onClick'>;
    /** component wrapper props */
    wrapperProps?: FlexBoxProps;
    /** callback to be executed once the text field changes */
    onChange?(text: string): void;
    /** whether the field should be displayed in a compact (smaller) manner */
    isCompact?: boolean;
    /**
     * whether the onSearch callback should be debounced or not. If true,
     * onSearch will be executed in a debounced fashion when the user enters
     * a text in the text field. By default this is false, meaning that onSearch
     * is executed when the Enter key is pressed, or the search icon button is clicked
     */
    debounced?: boolean;
    /** default value for the text field */
    value?: string;
    /** whether the entire component is disabled or not */
    isDisabled?: boolean;
    /**
     * whether the component is a standalone form or not. Use value false only if you
     * use this component inside a form. If true, (by default) the component will be a
     * form and the magnifying glass button will be of type "submit". If false, the
     * component will be a div or whatever you pass in wrapperProps.as and the button
     * will be of type "button".
     */
    isForm?: boolean;
}

const CLASSNAME = 'lumx-search-field';
/**
 * Component that groups a Text field and an Icon button in order to create a search field.
 * @family Filters
 * @param SearchFieldProps
 * @returns SearchField
 */
export const SearchField: React.FC<SearchFieldProps> = ({
    textFieldProps: rawTextFieldProps,
    iconButtonProps: rawIconButtonProps,
    wrapperProps: rawWrapperProps,
    onSearch,
    label,
    debounced = false,
    isCompact = false,
    onChange,
    value = '',
    isDisabled = false,
    isForm = true,
}) => {
    const [text, setText] = React.useState(value);
    const { translateKey } = useTranslate();
    const { block, element } = useClassnames(CLASSNAME);

    /**
     * If either the text field or the icon button are disabled, then the entire component
     * is disabled.
     */
    const isSearchFieldDisabled = isDisabled || rawTextFieldProps?.isDisabled || rawIconButtonProps?.isDisabled;

    React.useEffect(() => {
        setText(value);
    }, [value]);

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const debouncedSearch = React.useCallback(
        debounce((filter: string) => {
            onSearch(filter);
        }, DEBOUNCE_DELAY),
        [onSearch],
    );

    /**
     * Callback to be executed when the search field text changes.
     * @param searchedText - text searched
     */
    const onTextChanged = (searchedText: string) => {
        setText(searchedText);

        if (debounced) {
            debouncedSearch(searchedText);
        }

        if (onChange) {
            onChange(searchedText);
        }
    };

    const wrapperProps: SearchFieldProps['wrapperProps'] = {
        ...rawWrapperProps,
        ...(isForm
            ? {
                  as: 'form' as ElementType,
                  onSubmit: (event: any) => {
                      if (event) {
                          event.preventDefault();
                          event.stopPropagation();
                      }

                      onSearch(text);
                  },
                  // Disable form auto validation and manage it ourselves with the slice
                  noValidate: true,
              }
            : {}),
    };

    const textFieldProps: SearchFieldProps['textFieldProps'] = {
        ...rawTextFieldProps,
        ...(isForm || debounced
            ? {}
            : {
                  // Managing the 'Enter' key manually is needed when the component is not a form
                  onKeyPress: (event: any) => {
                      if (event.key === 'Enter') {
                          onSearch(text);
                      }
                  },
                  onClear: () => {
                      rawTextFieldProps?.onClear?.();
                      onSearch('');
                  },
              }),
    };

    const iconButtonProps: SearchFieldProps['iconButtonProps'] = {
        ...rawIconButtonProps,
        ...{
            type: (isForm ? 'submit' : 'button') as IconButtonProps['type'],
            onClick: isForm || debounced ? undefined : () => onSearch(text),
        },
    };

    return (
        <FlexBox
            {...wrapperProps}
            orientation={Orientation.horizontal}
            className={block({ compact: isCompact }, [wrapperProps?.className])}
            role="search"
        >
            <TextField
                clearButtonProps={{ label: translateKey(GLOBAL.CLEAR) }}
                placeholder={label}
                aria-label={label}
                role="searchbox"
                {...textFieldProps}
                onChange={onTextChanged}
                className={element('text-field', [textFieldProps?.className])}
                value={text}
                isDisabled={isSearchFieldDisabled}
            />
            <IconButton
                label={label}
                icon={mdiMagnify}
                emphasis={Emphasis.medium}
                {...iconButtonProps}
                className={element('icon-button', [iconButtonProps?.className])}
                isDisabled={isSearchFieldDisabled}
            />
        </FlexBox>
    );
};
