/**
 * Retrieves the base URL of the login application. The only format supported for
 * router are the following
 * - /login
 * - /{instance-slug}/login
 * @param url - URL normally retrieved from window.location.pathname
 */
const getBaseUrl = (url: string) => {
    // remove leading and trailing slash
    const trimmedPath = url.replace(/^\/|\/$/g, '');
    const splitPath = trimmedPath.split('/');

    if (splitPath[0] === 'login') {
        // if the pathname start with `/login/` the instance has not been
        // provided, we can use /login as a basePath for the router
        return '/login';
    }
    // use the first part of the pathname as an instance slug
    return `/${splitPath[0]}/login`;
};

export { getBaseUrl };
