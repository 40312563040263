import React, { useMemo } from 'react';

import upperFirst from 'lodash/upperFirst';

import { useClassnames } from '@lumapps/classnames';
import { mdiAccountKey } from '@lumapps/lumx/icons';
import { Button, Thumbnail, FlexBox, Orientation, Size, Icon, ColorPalette, Text } from '@lumapps/lumx/react';
import { GLOBAL, useTranslate } from '@lumapps/translations';
import { lightenDarkenColor } from '@lumapps/utils/color';

import { LOGIN_METHODS } from '../../constants';
import { LoginProviderButtonProps } from '../../types';
import { SIGN_IN_CORE } from '../keys';
import './index.scss';

const CLASSNAME = 'login-provider-button';

export const LoginProviderButton: React.FC<LoginProviderButtonProps> = ({
    leftImage,
    loginMethod,
    name,
    url,
    backgroundColor,
    autoFocusButton,
    isDisabled = false,
    ...props
}) => {
    const { translateAndReplace, translateKey } = useTranslate();
    const { block, element } = useClassnames(CLASSNAME);
    const backgroundColorDarker = backgroundColor && lightenDarkenColor(backgroundColor, -12);
    const backgroundColorDarkest = backgroundColor && lightenDarkenColor(backgroundColor, -30);
    /**
     * Button text are computed depending on the provider type.
     *      If no `loginMethod` is given return "Log in"
     *      Google, Okta and Microsoft should display the text "Login with <ProviderType>"
     *      Email should display "Login with LumApps"
     *      SAML should display "Login with <SAML provider name>"
     */
    const loginButtonText = useMemo(() => {
        if (!loginMethod) {
            return translateKey(GLOBAL.LOG_IN);
        }

        let text: string;
        switch (loginMethod) {
            case LOGIN_METHODS.SAML:
                text = name;
                break;
            case LOGIN_METHODS.EMAIL:
                text = loginMethod.toLowerCase();
                break;
            default:
                text = upperFirst(loginMethod.toLowerCase());
                break;
        }

        return translateAndReplace(SIGN_IN_CORE.LOGIN_GENERIC_SIGN_IN, { PROVIDER: text });
    }, [loginMethod, name, translateAndReplace, translateKey]);
    return (
        <div className={element('container')}>
            <Button
                autoFocus={autoFocusButton}
                href={loginMethod !== LOGIN_METHODS.EMAIL ? url : undefined}
                className={block(loginMethod?.toLowerCase())}
                style={{
                    '--lumx-button-emphasis-high-state-default-theme-light-background-color':
                        backgroundColor && backgroundColor,
                    '--lumx-button-emphasis-high-state-hover-theme-light-background-color': backgroundColorDarker,
                    '--lumx-button-emphasis-high-state-active-theme-light-background-color': backgroundColorDarkest,
                }}
                isDisabled={isDisabled}
                {...props}
            >
                <FlexBox orientation={Orientation.horizontal}>
                    <FlexBox className={element('logo-wrapper')}>
                        <div className={element('logo')}>
                            {leftImage ? (
                                <Thumbnail image={leftImage} alt="" size={Size.xs} />
                            ) : (
                                <Icon icon={mdiAccountKey} color={ColorPalette.dark} />
                            )}
                        </div>
                    </FlexBox>
                    <FlexBox className={element('text')}>
                        <Text as="span" truncate>
                            {loginButtonText}
                        </Text>
                    </FlexBox>
                </FlexBox>
            </Button>
        </div>
    );
};
