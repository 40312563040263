import React from 'react';

import { classnames, useClassnames, visuallyHidden } from '@lumapps/classnames';
import { get } from '@lumapps/constants';
import { Card } from '@lumapps/lumx-cards/components/Card';
import { SkeletonTypography, Typography, Text, Heading } from '@lumapps/lumx/react';

import { PoweredBy } from '../PoweredBy';
import { LoadingContent } from './LoadingContent';
import { LoginPageProps } from './LoginPageProps';

import './index.scss';

const CLASSNAME = 'login-page';
const TITLE_ID = 'login-page-form-title';
const { instanceName } = get();

/**
 * Title to be displayed on the login page
 * @param LoginPageProps
 * @returns Title
 */
export const Title: React.FC<Pick<LoginPageProps, 'title' | 'isLoading' | 'hideTitle'>> = ({
    isLoading,
    title,
    hideTitle,
}) => {
    const { element } = useClassnames(CLASSNAME);
    const className = element('title', undefined, hideTitle && visuallyHidden());
    if (isLoading) {
        return <SkeletonTypography className={className} typography={Typography.display1} />;
    }

    return (
        <Heading as="h1" id={TITLE_ID} className={className}>
            <Text as="span">{title}</Text>
        </Heading>
    );
};

/**
 * Subtitle to be displayed on the login page
 * @param LoginPageProps
 * @returns Subtitle
 */
export const Subtitle: React.FC<Pick<LoginPageProps, 'subtitle' | 'isLoading'>> = ({ isLoading, subtitle }) => {
    const { element } = useClassnames(CLASSNAME);

    if (subtitle) {
        if (isLoading) {
            return (
                <SkeletonTypography
                    className={classnames(element('skeleton-subtitle'))}
                    width="200px"
                    typography={Typography.body1}
                />
            );
        }

        return (
            <Heading as="h2" className={element('subtitle')}>
                <Text as="span">{subtitle}</Text>
            </Heading>
        );
    }

    return null;
};

/**
 * Renders the page for the login, with the provided title subtitle and content.
 * @param LoginPageProps
 * @returns Login Page
 */
export const LoginPage: React.FC<LoginPageProps> = ({
    before,
    title,
    hideTitle,
    subtitle,
    children,
    isLoading,
    logo,
    displayPoweredBy = true,
}) => {
    const { block, element } = useClassnames(CLASSNAME);

    return (
        <Card as="section" className={block()}>
            {before}

            <img className={element('logo')} src={logo} alt={instanceName} />

            <Title title={title} isLoading={isLoading} hideTitle={hideTitle} />

            <Subtitle subtitle={subtitle} isLoading={isLoading} />

            <div className={element('content')}>{!isLoading ? children : <LoadingContent className={block()} />}</div>
            {displayPoweredBy && !isLoading ? <PoweredBy isLoading={false} /> : null}
        </Card>
    );
};
