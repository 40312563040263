import { communityPostView, communityView } from '@lumapps/communities/routes';
import { getContentListMobilePageDescriptor } from '@lumapps/content-lists/routes';
import { ContentTypes } from '@lumapps/content-types/types';
import { getDirectoryPageMobilePageDescriptor } from '@lumapps/directories-page/routes';
import { OptionalAppRoute, Route } from '@lumapps/router';
import { AppId, URL_PREFIX } from '@lumapps/router/constants';
import { isExternalUrl } from '@lumapps/router/utils';
import { getUserDirectoryMobilePageDescriptor, userDirectoryView } from '@lumapps/user-directory-front-office/routes';

import { ContentLinkRef } from '../types';

/* Paths: */
export const homePath = '/';
export const legacyContentPath = '/*';
export const contentViewIdPath = `/${URL_PREFIX}/content/id/:id`;
// `*` is the content slug, but it can contain multiple parts if nested in the main nav or sub nav
export const contentViewPath = `/${URL_PREFIX}/content/:id/*`;

// (won't be needed in react-router v6 since `/:id/*` will also match `/:id`, no need to the slash)
const contentViewBasePath = `/${URL_PREFIX}/content/:id`;

/* Routes: */
export const legacyContentViewRoute: Route<string[]> = {
    path: [
        /** Legacy content URL. */
        legacyContentPath,
        /** Home page URL. Keep it at the end of the array or else params from other paths won't be reachable. */
        homePath,
    ],
    legacyId: 'app.front.content-get',
    appId: AppId.legacy,
};

export const homePageViewRoute: OptionalAppRoute = {
    path: homePath,
    legacyId: 'app.front.content-get',
};

export const contentViewRoute: Route<string[]> = {
    path: [contentViewIdPath, contentViewPath, contentViewBasePath, homePath],
    appId: AppId.frontOffice,
};

const getContentMobilePageDescriptor = (id: string, anchor?: string): Route['mobilePageDescriptor'] => ({
    id,
    type: 'content',
    section: anchor?.match(/#?comments/) ? 'comments' : undefined,
});

const externalLinkRoute = ({ link, query, anchor }: Pick<ContentLinkRef, 'link' | 'query' | 'anchor'>): Route => {
    return {
        appId: AppId.external,
        anchor,
        query,
        path: link as string,
    };
};

export const homePageView = ({
    isV2Compatible,
    anchor,
    query,
    instanceSlug,
}: {
    isV2Compatible?: boolean;
    anchor?: Route['anchor'];
    query?: Route['query'];
    instanceSlug?: string;
}): Route => {
    if (isV2Compatible) {
        return {
            ...homePageViewRoute,
            anchor,
            query,
            instanceSlug,
            legacyId: undefined,
            appId: AppId.frontOffice,
        };
    }
    return {
        ...homePageViewRoute,
        anchor,
        query,
        instanceSlug,
        // Required for the legacy to know we actually changed page
        params: { slug: null } as any,
        appId: AppId.legacy,
    };
};

/* Parameterized routes: */
const contentPageView = (
    { id, slug, query, anchor, instance, isV2Compatible, keepParentPath }: ContentLinkRef,
    isLayoutEnabled: boolean,
    mobilePageDescriptor?: Route['mobilePageDescriptor'],
): Route => {
    const instanceSlug = instance?.slug;
    const correctedSlug = (keepParentPath ? slug : slug?.split('/').pop()) || '';

    // Layout v2 content by id (and optionally slug).
    if (id && isLayoutEnabled && isV2Compatible) {
        return {
            ...contentViewRoute,
            path: contentViewPath,
            params: {
                id,
                // Slug path '*' in the path pattern
                '0': correctedSlug,
            },
            anchor,
            query,
            instanceSlug,
            mobilePageDescriptor,
        };
    }
    // Legacy layout v1 route.
    return {
        ...legacyContentViewRoute,
        path: legacyContentPath,
        params: {
            // Slug param is needed for the angular router
            slug: correctedSlug,
            // Slug path '*' in the path pattern
            '0': correctedSlug,
        },
        anchor,
        query,
        instanceSlug,
        mobilePageDescriptor,
    };
};

/**
 * Switches between the legacy & new app content view route depending on the layout FF and the content migration status.
 */
/**
 *
 * */
export function contentView(params: {
    to: ContentLinkRef;
    isLayoutEnabled: boolean;
    isReactUserDirectoryEnabled?: boolean;
}): Route {
    const {
        id,
        slug,
        title,
        type,
        isHomePage,
        anchor,
        instance,
        isV2Compatible,
        query,
        externalKey,
        link = isExternalUrl(slug) ? slug : undefined,
    } = params.to;
    const instanceSlug = instance?.slug;

    // Home page.
    if (isHomePage) {
        return homePageView({ isV2Compatible: params.isLayoutEnabled && isV2Compatible, instanceSlug, anchor, query });
    }

    // Content external link.
    if (link) {
        return externalLinkRoute({ link, anchor, query });
    }

    /**
     * Redirect to the route associated with the content type.
     * */
    switch (type) {
        case ContentTypes.COMMUNITY:
            return communityView({ slug: slug as string, id, anchor, instanceSlug });
        case ContentTypes.POST:
            return communityPostView({ slug: slug as string, postId: id as string, anchor, instanceSlug });
        case ContentTypes.USER_DIRECTORY:
            if (params.isReactUserDirectoryEnabled && id) {
                return userDirectoryView({ contentId: id, slug: slug as string, instanceSlug });
            }
            return contentPageView(params.to, false, id ? getUserDirectoryMobilePageDescriptor(id) : undefined);
        case ContentTypes.DIRECTORY:
            return contentPageView(
                params.to,
                params.isLayoutEnabled,
                externalKey && title ? getDirectoryPageMobilePageDescriptor(externalKey, title) : undefined,
            );
        case ContentTypes.CUSTOM_LIST:
            return contentPageView(
                params.to,
                params.isLayoutEnabled,
                id ? getContentListMobilePageDescriptor(id, externalKey) : undefined,
            );
        default:
            break;
    }

    return contentPageView(
        params.to,
        params.isLayoutEnabled,
        id ? getContentMobilePageDescriptor(id, anchor) : undefined,
    );
}
