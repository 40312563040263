import React from 'react';

import { Kind, Message, Text } from '@lumapps/lumx/react';
import { useQueryParams } from '@lumapps/router';
import { useTranslate } from '@lumapps/translations';

import { LOGOUT_URL } from '../../constants';
import { SIGN_IN, LOGOUT } from '../../keys';

import './index.scss';

const CLASSNAME = 'login-message';
export const LoginMessage: React.FC = () => {
    const { translateAndReplace } = useTranslate();
    const { error, accountType, logout, email } = useQueryParams();

    const getErrorTranslationKey = () => {
        return SIGN_IN[error as keyof typeof SIGN_IN];
    };
    const getInfoTranslationKey = () => {
        return LOGOUT[accountType as keyof typeof LOGOUT];
    };
    return (
        <>
            {error && getErrorTranslationKey() ? (
                <Message className={CLASSNAME} kind={Kind.error} hasBackground>
                    <Text
                        as="p"
                        // eslint-disable-next-line react/no-danger
                        dangerouslySetInnerHTML={{
                            __html:
                                accountType && LOGOUT_URL[accountType]
                                    ? translateAndReplace(getErrorTranslationKey(), {
                                          EMAIL: email || '',
                                          LOGOUT_URL: LOGOUT_URL[accountType].url,
                                      })
                                    : translateAndReplace(getErrorTranslationKey(), {
                                          EMAIL: '',
                                          LOGOUT_URL: '#', // To be changed ( Add Okta link href )
                                      }),
                        }}
                    />
                </Message>
            ) : null}
            {accountType && logout === 'true' && getInfoTranslationKey() ? (
                <Message className={CLASSNAME} kind={Kind.info} hasBackground>
                    <Text
                        as="p"
                        // eslint-disable-next-line react/no-danger
                        dangerouslySetInnerHTML={{
                            __html: LOGOUT_URL[accountType]
                                ? translateAndReplace(getInfoTranslationKey(), {})
                                : translateAndReplace(getInfoTranslationKey(), {
                                      LOGOUT_URL: '#', // To be changed ( Add Okta link href )
                                  }),
                        }}
                    />
                </Message>
            ) : null}
        </>
    );
};
