export enum SIGN_IN {
    /**
     * https://app.lokalise.com/project/971428945b55f3d6334c27.85957847/?k=13453183
     */
    ADMIN_USER_DIRECTORY_PASSWORD = 'LOGIN_PASSWORD',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=407053893
     */
    NOT_AUTHORIZED_LOGIN = 'LOGIN.NOT_AUTHORIZED_LOGIN',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=407053926
     */
    GENERIC_ERROR_LOGIN = 'LOGIN.GENERIC_ERROR_LOGIN',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=407053933
     */
    TOO_MANY_ATTEMPTS = 'LOGIN.TOO_MANY_ATTEMPTS',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=407053955
     */
    PASSWORD_API_UPDATE_ERROR = 'LOGIN.PASSWORD_API_UPDATE_ERROR',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=407054050
     */
    INVALID_CAPTCHA = 'LOGIN.INVALID_CAPTCHA',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=407054055
     */
    USER_CREATION_FAILED = 'LOGIN.ERROR_USER_CREATION_FAILED',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=407053881
     */
    NEED_LOGIN = 'LOGIN.NEED_LOGIN',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=407050479
     */
    INVALID_LOGIN_SESSION_EXPIRED = 'LOGIN.INVALID_LOGIN_SESSION_EXPIRED',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=432330679
     */
    INVALID_LOGIN = 'LOGIN.INVALID_LOGIN',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=432330772
     */
    INVALID_SAML_CONFIGURATION = 'LOGIN.INVALID_SAML_CONFIGURATION',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=184115544
     */
    PASSWORD_NOT_ENOUGH_CHARACTERS = 'PASSWORD_NOT_ENOUGH_CHARACTERS',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=184119612
     */
    ENTER_CREDENTIALS = 'ENTER_CREDENTIALS',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=184119413
     */
    WELCOME_LOGIN_OPTIONS_SUBTITLE = 'WELCOME_LOGIN_OPTIONS_SUBTITLE',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=184119470
     */
    SIGN_IN_WITH_LUMAPPS = 'SIGN_IN_WITH_LUMAPPS',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=10174455
     */
    WELCOME = 'FRONT.TOP_USER_BENEFITS.WELCOME_TITLE',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=184119491
     */
    EMAIL_ADDRESS = 'LOGIN_EMAIL_ADDRESS',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=184119390
     */
    INVALID_EMAIL_ERROR = 'ERROR_INVALID_EMAIL_ADDRESS',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=184115738
     */
    ENTER_EMAIL_TO_RESET_PASSWORD = 'ENTER_EMAIL_TO_RESET_PASSWORD',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=186258569
     */
    FORGOT_YOUR_PASSWORD = 'FORGOT_YOUR_PASSWORD',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=184115590
     */
    PASSWORD_RESET_DO_NOT_MATCH = 'PASSWORD_RESET_DO_NOT_MATCH',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=186323812
     */
    NEW_PASSWORD = 'NEW_PASSWORD',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=186323813
     */
    CONFIRM_PASSWORD = 'CONFIRM_PASSWORD',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=186323856
     */
    NOT_ENOUGH_CHARACTERS = 'NOT_ENOUGH_CHARACTERS',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=190242609
     */
    RESET_YOUR_PASSWORD = 'RESET_YOUR_PASSWORD',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=190242623
     */
    CHECK_YOUR_EMAILS = 'CHECK_YOUR_EMAILS',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=24478635
     */
    SHOW_MORE_LOGIN_OPTIONS = 'LOGIN.SHOW_MORE_LOGIN_OPTION',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=196950944
     */
    SHOW_LESS_LOGIN_OPTIONS = 'LOGIN.SHOW_LESS_LOGIN_OPTION',
}
export enum LOGOUT {
    /**
     * https://app.lokalise.com/project/971428945b55f3d6334c27.85957847/?k=13453177
     */
    GOOGLE = 'LOGIN_LOGOUT_GOOGLE',
    /**
     * https://app.lokalise.com/project/971428945b55f3d6334c27.85957847/?k=13453178
     */
    MICROSOFT = 'LOGIN_LOGOUT_MICROSOFT',
    /**
     * https://app.lokalise.com/project/971428945b55f3d6334c27.85957847/?k=13453179
     */
    OKTA = 'LOGIN_LOGOUT_OKTA',
}
